<template>
    <modal id="sale-visit-modal"
           v-model="showModal"
           :title="generateModalTitle('appointment')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url="selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/sale-visit`"
            @submit.prevent="submitData"
        >
            <app-form-group-selectable
                type="select"
                :label="$t('status')"
                list-value-field="name"
                v-model="formData.status"
                :required="true"
                :chooseLabel="$t('status')"
                :error-message="$errorMessage(errors, 'status')"
                :fetch-url="`${TENANT_BASE_URL}api/sale-visit/status`"
            />

            <app-form-group-selectable
                type="select"
                :label="$t('type')"
                list-value-field="name"
                v-model="formData.type"
                :required="true"
                :chooseLabel="$t('type')"
                :error-message="$errorMessage(errors, 'type')"
                :fetch-url="`${TENANT_BASE_URL}api/sale-visit/type`"
            />

            <app-form-group-selectable
                v-if="!cardId"
                type="search-select"
                :label="$t('card')"
                list-value-field="name"
                v-model="formData.card_id"
                :error-message="$errorMessage(errors, 'card_id')"
                :fetch-url="`${TENANT_BASE_URL}app/sale-card?per_page=0`"
                :required="true"
            />

            <app-form-group
                type="time"
                v-model="formData.start_time"
                :label="$t('start_time')"
                :error-message="$errorMessage(errors, 'start_time')"
                :placeholder="$t('enter_start_time')"
            />

            <app-form-group
                type="time"
                v-model="formData.end_time"
                :label="$t('end_time')"
                :error-message="$errorMessage(errors, 'end_time')"
                :placeholder="$t('enter_end_time')"
            />

            <app-form-group
                type="date"
                :label="$t('date')"
                :placeholder="$placeholder('date')"
                v-model="formData.date"
                :error-message="$errorMessage(errors, 'date')"
                :required="true"
            />

            <div class="form-group">
                <label>{{ $t('image') }}</label>
                <app-input
                    type="dropzone"
                    v-model="files"
                    :error-message="$errorMessage(errors, 'image.0')"
                />
            </div>

            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :text-area-rows="4"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>
        </form>
    </modal>
</template>

<script>
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {formatDateForServer, formatTimeForServer, today} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "VisitCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        cardId: null
    },
    data() {
        return {
            TENANT_BASE_URL,
            files   : [],
            formData: {
                date: formatDateForServer(today()),
            },
        }
    },
    methods: {
        submitData() {
            this.loading   = true
            const url      = this.$refs.form.dataset["url"];
            const formData = formDataAssigner(new FormData, this.formData);

            formData.set('date', formatDateForServer(this.formData.date))

            if (this.formData.start_time) {
                formData.set('start_time', formatTimeForServer(this.formData.start_time))
            }
            if (this.formData.end_time) {
                formData.set('end_time', formatTimeForServer(this.formData.end_time))
            }

            this.files.forEach(file => formData.append('image[]', file))

            if (this.cardId) {
                formData.set('card_id', this.cardId)
            }

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#sale-visit-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'sale-visit-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>


<template>
    <modal id="hotel-lost-found-process"
        v-model="showModal"
        :title="generateModalTitle('process')"
        @submit="submitData" :loading="loading"
        :preloader="preloader">

        <form
            ref="form"
            :data-url="selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/lost-found`"
            @submit.prevent="submitData"
        >
            <app-form-group-selectable
                type="select"
                :label="$t('status')"
                list-value-field="name"
                v-model="formData.status"
                :required="true"
                :chooseLabel="$t('status')"
                :error-message="$errorMessage(errors, 'status')"
                :fetch-url="`${TENANT_BASE_URL}selectable/hotel/lost-found-status`"
            />
            <app-form-group
                type="textarea"
                :label="$t('note')"
                :placeholder="$textAreaPlaceHolder('note')"
                v-model="formData.note"
                :required="false"
                :error-message="$errorMessage(errors, 'note')">
            </app-form-group>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formatDateForServer} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "HotelLostFoundProcessModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            TENANT_BASE_URL,
            formData: {
                status: 1,
            },
        }
    },
    methods: {
        submitData() {
            this.loading       = true
            this.formData.date = this.formData.date ? formatDateForServer(this.formData.date) : '';

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#hotel-lost-found-process').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'hotel-lost-found-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>


import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import {HotelFilterMixin, UserFilterMixin} from "./FilterMixin";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";
import moment from "moment";

export default {
    mixins: [HelperMixin, DatatableHelperMixin, UserFilterMixin, HotelFilterMixin],
    data() {
        const months = []
        const years  = []

        for (const i in moment.months()) {
            months.push({
                id   : parseInt(i) + 1,
                label: moment.months()[i],
            })
        }

        for (let i = moment().year(); i > moment().year() - 50; i--) {
            years.push({
                id   : i,
                label: i,
            })
        }

        return {
            options: {
                name             : this.$t('commission_details'),
                url              : `${TENANT_BASE_URL}app/commission`,
                showHeader       : true,
                enableRowSelect  : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : 'MM/YYYY',
                        type     : 'custom-html',
                        key      : 'month',
                        isVisible: true,
                        modifier : (month, row) => month + '/' + row.year
                    },
                    {
                        title    : this.$t('hotel'),
                        type     : 'custom-html',
                        key      : 'hotel',
                        isVisible: true,
                        modifier : (value) => value.name
                    },
                    {
                        title    : this.$t('employee_id'),
                        type     : 'custom-html',
                        key      : 'user',
                        isVisible: true,
                        modifier : (value) => value.profile.employee_id
                    },
                    {
                        title    : this.$t('employee_name'),
                        type     : 'custom-html',
                        key      : 'user',
                        isVisible: true,
                        modifier : (value) => value.full_name
                    },
                    {
                        title    : this.$t('housekeeping_fee'),
                        type     : 'text',
                        key      : 'housekeeping_fee',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('commute_fee'),
                        type     : 'text',
                        key      : 'commute_fee',
                        isVisible: true,
                    },
                    {
                        title        : this.$t('remark'),
                        type         : 'component',
                        key          : 'remark',
                        isVisible    : true,
                        componentName: 'app-commission-remark',
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('hotel'),
                        type          : "multi-select-filter",
                        key           : "hotels",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_list')
                    },
                    {
                        title         : this.$t('users'),
                        type          : "multi-select-filter",
                        key           : "users",
                        option        : [],
                        listValueField: 'full_name',
                        permission    : !!this.$can('view_users')
                    },
                    {
                        title         : this.$t('month'),
                        type          : "drop-down-filter",
                        key           : "month",
                        listValueField: 'label',
                        option        : months,
                        initValue     : moment().month() + 1,
                    },
                    {
                        title         : this.$t('year'),
                        type          : "drop-down-filter",
                        key           : "year",
                        listValueField: 'label',
                        option        : years,
                        initValue     : moment().year(),
                    },
                ],
                paginationOptions: [10, 40, 60, 80, 100, 200],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 0,
                showAction       : false,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [],
            }
        }
    },
}
import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        const tab = this.$parent.$props.tabs.find(e => e.component === 'app-hotel-option')
        return {
            urlGenerator,
            options: {
                name             : this.$t('hotel'),
                url              : `${TENANT_BASE_URL}app/hotel/member?hotel_id=${tab.hotel.id}`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                    {
                        title    : this.$t('name'),
                        type     : 'custom-html',
                        key      : 'id',
                        isVisible: true,
                        modifier : (data, row) => {
                            return row.user.full_name;
                        }
                    },
                    {
                        title    : this.$t('role'),
                        type     : 'text',
                        key      : 'role_name',
                        isVisible: true,
                    },
                    {
                        title        : this.$t('verified'),
                        type         : 'component',
                        key          : 'verified',
                        isVisible    : true,
                        componentName: 'app-hotel-member-verified',
                    },
                    {
                        title    : this.$t('email'),
                        type     : 'custom-html',
                        key      : 'id',
                        isVisible: true,
                        modifier : (data, row) => {
                            return row.user.email;
                        }
                    },
                    {
                        title    : this.$t('employee_id'),
                        type     : 'custom-html',
                        key      : 'id',
                        isVisible: true,
                        modifier : (data, row) => {
                            if (row.user) {
                                return row.user.profile.employee_id;
                            }
                            return '';
                        }
                    },
                    {
                        title    : this.$t('phone'),
                        type     : 'custom-html',
                        key      : 'id',
                        isVisible: true,
                        modifier : (data, row) => {
                            return row.user.profile.phone_number;
                        }
                    },
                    {
                        title    : this.$t('facebook'),
                        type     : 'custom-html',
                        key      : 'id',
                        isVisible: true,
                        modifier : (data, row) => {
                            if (row.is_leader === 1 && row.user.social_links.length > 0) {
                                const facebook = row.user.social_links.find(item => item.key === 'facebook')
                                if (!facebook) {
                                    return '';
                                }
                                return `<span class="text-danger">${facebook.value}</span>`;
                            }
                            if (row.is_leader === 0 && row.user.social_links.length > 0) {
                                const facebook = row.user.social_links.find(item => item.key === 'facebook')
                                if (!facebook) {
                                    return '';
                                }
                                return facebook.value
                            }
                            return '';
                        }
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('move_member'),
                        icon    : 'corner-up-right',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_member')
                    },
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_member')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/member/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_hotel_member')
                    }
                ],
            }
        }
    },
}
import {axiosGet, urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";
import {HotelFilterMixin} from "./FilterMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";

export default {
	mixins: [HelperMixin, DatatableHelperMixin, HotelFilterMixin],
	data() {
		return {
			urlGenerator,
			options: {
				name             : this.$t('claim'),
				url              : this.hotel.id ?
					`${TENANT_BASE_URL}app/hotel/claim?hotels=${this.hotel.id}` :
					`${TENANT_BASE_URL}app/hotel/claim`,
				showHeader       : true,
				tableShadow      : false,
				tablePaddingClass: 'pt-primary',
				columns          : [
					{
						title    : this.$t('actions'),
						type     : 'action',
						key      : 'id',
						isVisible: true
					},
					{
						title    : this.$t('type'),
						type     : 'custom-html',
						key      : 'type',
						isVisible: true,
						modifier : (type) => {
							return type ? type.title : '';
						}
					},
					{
						title    : this.$t('title'),
						type     : 'text',
						key      : 'title',
						isVisible: true,
					},
					{
						title    : this.$t('prevention'),
						type     : 'text',
						key      : 'prevention',
						isVisible: true,
					},
					{
						title    : this.$t('remark'),
						type     : 'textarea',
						key      : 'remark',
						isVisible: true,
					},
					{
						title    : this.$t('date'),
						type     : 'custom-html',
						key      : 'date',
						isVisible: true,
						modifier : (value) => {
							return formatDateToLocal(value);
						}
					},
					{
						title    : this.$t('processing_status'),
						type     : 'text',
						key      : 'status_text',
						isVisible: true,
					},
				],
				filters          : [
					{
						title         : this.$t('hotel'),
						type          : "multi-select-filter",
						key           : "hotels",
						option        : [],
						listValueField: 'name',
						permission    : !!this.$can('view_hotel_list') && !this.hotel.id,
					},
					{
						title         : this.$t('floor'),
						type          : "multi-select-filter",
						key           : "floor",
						option        : [],
						listValueField: 'name',
						permission    : !!this.$can('view_hotel_list') && !this.hotel.id,
					},
					{
						title         : this.$t('room_number'),
						type          : "multi-select-filter",
						key           : "rooms",
						option        : [],
						listValueField: 'name',
						permission    : !!this.$can('view_hotel_list') && !this.hotel.id,
					},
					{
						title : this.$t('date'),
						type  : "range-picker",
						key   : "date",
						option: ["today", "thisMonth", "last7Days", "thisYear"]
					},
				],
				paginationType   : "pagination",
				responsive       : true,
				rowLimit         : 10,
				showAction       : true,
				orderBy          : 'desc',
				actionType       : 'default',
				actions          : [
					{
						title   : this.$t('edit'),
						icon    : 'edit',
						type    : 'modal',
						modifier: row => this.$can('update_hotel_claim')
					},
					{
						title    : this.$t('delete'),
						icon     : 'trash-2',
						type     : 'modal',
						component: 'app-confirmation-modal',
						modalId  : 'app-confirmation-modal',
						url      : `${TENANT_BASE_URL}app/hotel/claim/`,
						name     : 'delete',
						modifier : row => this.$can('update_hotel_claim')
					}
				],
			}
		}
	},
	mounted() {
		if (this.hotel.id) {
			this.getFilterValues({hotels: this.hotel.id})
		} else {
			this.getFilterValues(null)
		}
	},
	methods: {
		getFilterValues(value) {
			if (value.hotels) {
				const Error       = error => this.$toastr.e(error.response.data.message)
				const floorFilter = this.options.filters.find(item => item.key === 'floor');
				const roomFilter  = this.options.filters.find(item => item.key === 'rooms');

				if (value.hotels) {
					floorFilter.option = []
					roomFilter.option  = []
					axiosGet(`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${value.hotels}&group=floor&format=floor`)
						.then(({data}) => floorFilter.option = data)
						.catch(Error)
				}

				if (value.floor) {
					axiosGet(`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${value.hotels}&floor=${value.floor}`)
						.then(({data}) => roomFilter.option = data)
						.catch(Error)
				}
			}
		},
	},
}
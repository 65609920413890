import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";
import {HotelFilterMixin} from "./FilterMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";
import {axiosGet} from "../../../common/Helper/AxiosHelper";

export default {
	mixins: [HelperMixin, DatatableHelperMixin, HotelFilterMixin],
	data() {
		return {
			options: {
				name             : this.$t('house-keeping'),
				url              : this.hotel.id ?
					`${TENANT_BASE_URL}app/hotel/house-keeping?hotels=${this.hotel.id}` :
					`${TENANT_BASE_URL}app/hotel/house-keeping`,
				showHeader       : true,
				tableShadow      : false,
				tablePaddingClass: 'pt-primary',
				columns          : [
					{
						title    : this.$t('date'),
						type     : 'custom-html',
						key      : 'date',
						isVisible: true,
						modifier : (value) => {
							return formatDateToLocal(value);
						}
					},
					{
						title    : this.$t('start_time'),
						type     : 'text',
						key      : 'start_time',
						isVisible: true,
					},
					{
						title    : this.$t('end_time'),
						type     : 'text',
						key      : 'end_time',
						isVisible: true,
					},
					{
						title    : this.$t('hotel'),
						type     : 'custom-html',
						key      : 'hotel',
						isVisible: true,
						modifier : (hotel) => {
							return hotel ? '<span class="text-nowrap">' + hotel.name + '</span>' : '';
						}
					},
					{
						title    : this.$t('room'),
						type     : 'custom-html',
						key      : 'room',
						isVisible: true,
						modifier : (room) => {
							return room ? room.number : '';
						}
					},
					{
						title    : this.$t('type'),
						type     : 'custom-html',
						key      : 'room_type',
						isVisible: true,
						modifier : (type) => {
							return type ? '<span class="text-nowrap">' + type.name + '</span>' : '';
						}
					},
					{
						title    : this.$t('staying'),
						type     : 'custom-html',
						key      : 'staying',
						isVisible: true,
						modifier : (staying) => {
							return staying ? '<span class="text-nowrap">' + staying.name + '</span>' : '';
						}
					},
					{
						title    : this.$t('cleaning'),
						type     : 'custom-html',
						key      : 'cleaning',
						isVisible: true,
						modifier : (cleaning) => {
							return cleaning ? '<span class="text-nowrap">' + cleaning.name + '</span>' : '';
						}
					},
					{
						title    : this.$t('payment'),
						type     : 'text',
						key      : 'payment',
						isVisible: true,
					},
					{
						title        : this.$t('confirmed'),
						type         : 'component',
						key          : 'confirmed',
						isVisible    : true,
						componentName: 'app-hotel-house-keeping-confirmed',
					},
					{
						title        : this.$t('options'),
						type         : 'expandable-column',
						key          : 'options',
						isVisible    : true,
						componentName: 'app-hotel-house-keeping-option',
						modifier     : (options) => {
							return {
								title     : this.$t('options') + ' (' + options.length + ')',
								expandable: options.length > 0,
								className : 'success'
							};
						}
					},
					{
						title    : this.$t('actions'),
						type     : 'action',
						key      : 'id',
						isVisible: true
					},
				],
				filters          : [
					{
						title         : this.$t('hotel'),
						type          : "multi-select-filter",
						key           : "hotels",
						option        : [],
						listValueField: 'name',
						permission    : !!this.$can('view_hotel_list') && !this.hotel.id,
					},
					{
						title         : this.$t('member'),
						type          : "multi-select-filter",
						key           : "maid_id",
						option        : [],
						listValueField: 'employee_name',
						permission    : !!this.$can('view_hotel_member')
					},
					{
						title : this.$t('date'),
						type  : "range-picker",
						key   : "date",
						option: ["today", "thisMonth", "last7Days", "thisYear"]
					},
				],
				paginationType   : "pagination",
				responsive       : true,
				rowLimit         : 10,
				showAction       : true,
				orderBy          : 'desc',
				actionType       : 'default',
				actions          : [
					{
						title   : this.$t('edit'),
						icon    : 'edit',
						type    : 'modal',
						modifier: row => this.$can('update_hotel_house_keeping')
					},
					{
						title    : this.$t('delete'),
						icon     : 'trash-2',
						type     : 'modal',
						component: 'app-confirmation-modal',
						modalId  : 'app-confirmation-modal',
						url      : `${TENANT_BASE_URL}app/hotel/house-keeping/`,
						name     : 'delete',
						modifier : row => this.$can('update_hotel_house-keeping')
					}
				],
			}
		}
	},
	mounted() {
		if (this.hotel.id) {
			this.getFilterValues({hotels: this.hotel.id})
		} else {
			this.getFilterValues(null)
		}
	},
	methods: {
		getFilterValues(value) {
			const Error        = error => this.$toastr.e(error.response.data.message)
			const optionFilter = this.options.filters.find(item => item.key === 'maid_id');

			axiosGet(`${TENANT_BASE_URL}selectable/hotel/member${value && value.hotels ? '?hotel_id=' + value.hotels : ''}`)
				.then(({data}) => optionFilter.option = data)
				.catch(Error)
		},
	},
}
import {DOCUMENTS} from '../../Config/ApiUrl'
import {ucFirst} from "../../../common/Helper/Support/TextHelper";
import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('document'),
                url              : DOCUMENTS + `?user_id=${this.props.id}`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                // cardViewComponent: 'app-tenant-document-card-view',
                columns       : [
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        isVisible: true
                    },
                    {
                        title    : this.$t('name'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('expired'),
                        type     : 'custom-html',
                        key      : 'expired',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                    {
                        title    : this.$t('verified'),
                        type     : 'custom-html',
                        key      : 'verified',
                        isVisible: true,
                        modifier : (value, row) => value === 1 ? 'Yes' : 'No'
                    },
                    // {
                    //     title: this.$t('attachment'),
                    //     type: 'custom-html',
                    //     key: 'path',
                    //     modifier: (value, row) => value ? `<a href="${urlGenerator(`app/documents/${row.id}/download`)}" target="_blank">
                    //                                             <i data-feather="download"/>
                    //                                        </a>` : '-'
                    // },
                    {
                        title   : this.$t('attachment'),
                        type    : 'custom-html',
                        key     : 'path',
                        modifier: (value, row) => {
                            const html     = [];
                            const basename = (path) => path.split('/').reverse()[0]

                            if (value.length > 0) {
                                for (const path of value) {
                                    html.push(`<a href="${urlGenerator(path)}" target="_blank">${basename(path)}</a>`)
                                }
                            }

                            return html.join('<br>')
                        }
                    },
                    {
                        title    : this.$t('added_by'),
                        type     : 'object',
                        key      : 'created_by',
                        isVisible: true,
                        modifier : (value) => {
                            return value.full_name;
                        }
                    },
                ],
                filters       : [],
                paginationType: "pagination",
                responsive    : true,
                rowLimit      : 10,
                showAction    : true,
                orderBy       : 'desc',
                actionType    : "default",
                actions       : [
                    {
                        title    : this.$t('edit'),
                        icon     : 'edit',
                        type     : 'modal',
                        component: 'app-document-modal',
                        modalId  : 'document-modal',
                        url      : DOCUMENTS,
                        name     : 'edit',
                        modifier : row => true
                    },
                    {
                        title     : this.$t('delete'),
                        name      : 'delete',
                        icon      : 'trash-2',
                        modalClass: 'warning',
                        url       : DOCUMENTS,
                        modifier  : row => true
                    },
                ],
            }
        }
    }
}
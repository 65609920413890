<template>
    <modal id="document-modal"
           v-model="showModal"
           :title="selectedUrl ? $t('edit_document') : $t('add_document')"
           @submit="submitData"
           :loading="loading"
           :preloader="preloader">
        <form ref="form" :data-url="selectedUrl ? selectedUrl : apiUrl.DOCUMENTS">

            <app-form-group
                :label="$t('name')"
                type="text"
                v-model="formData.name"
                :placeholder="$placeholder('name', '')"
                :error-message="$errorMessage(errors, 'name')"
                :options="options"
                :required="true"
            />
            <!--
            <app-form-group
                :label="$t('name')"
                v-model="formData.name"
                type="text"
                :placeholder="$placeholder('name', '')"
                :required="true"
                :error-message="$errorMessage(errors, 'name')"
            />
            -->

            <div class="form-group">
                <label>{{ $t('document') }}</label>
                <app-input
                    type="dropzone"
                    v-model="files"
                    :error-message="$errorMessage(errors, 'files.0')"
                />
                <small class="text-muted font-italic mt-3 d-inline-block">
                    {{ $t('document_recommendation') }}
                </small>
            </div>

            <app-form-group
                type="date"
                :label="$t('expired')"
                v-model="formData.expired"
                :placeholder="formData.expired ? $placeholder('date') : this.$t('not_added_yet')"
                :error-message="$errorMessage(errors, 'expired')"
            />

            <app-form-group
                type="single-checkbox"
                :list-value-field="$t('verified')"
                v-model="formData.verified"
            />

        </form>
    </modal>
</template>

<script>
import ModalMixin from "../../../../../../common/Mixin/Global/ModalMixin";
import FormHelperMixins from "../../../../../../common/Mixin/Global/FormHelperMixins";
import {formDataAssigner} from "../../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../../common/Config/UrlHelper";
import {axiosGet} from "../../../../../../common/Helper/AxiosHelper";
import * as apiUrl from "../../../../../Config/ApiUrl";
import {formatDateForServer} from "../../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "DocumentCreateEditModal",
    mixins: [ModalMixin, FormHelperMixins],
    props : {
        userId: {
            required: true
        }
    },
    data() {
        return {
            files   : [],
            options : [],
            formData: {
                verified: false,
                name    : null,
            },
        }
    },
    mounted() {
        axiosGet(apiUrl.DOCUMENTS + '?per_page=-1&user_id=' + window.user.id).then(({data}) => {
            for (const item of data) {
                this.options.push(item.name)
            }
        }).catch(error => {
            this.$toastr.e(error.response.data.message)
        });
    },
    methods: {
        TENANT_BASE_URL() {
            return TENANT_BASE_URL
        },
        submitData() {
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);
            formData.append('user_id', this.userId);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            if (this.formData.expired) {
                formData.set('expired', formatDateForServer(this.formData.expired))
            }

            this.files.forEach(file => formData.append('files[]', file))

            this.loading = true

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.toastAndReload(data.message, 'documents-table');
            this.formData = {};
            $('#document-modal').modal('hide');
            this.$emit('input', false);
        },
        afterSuccessFromGetEditData({data}) {
            this.formData.verified = data.verified;
            this.formData.name     = data.name;
            this.formData.expired  = data.expired;
            this.files             = [];
            this.preloader         = false;
        },
    },
}
</script>

<style scoped>
</style>
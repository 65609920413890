<template>
    <modal id="work-place-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('work_place')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/work-place`'
            @submit.prevent="submitData"
        >
            <div class="form-group">
                <label>{{ $t('attachments') }}</label>
                <app-input
                    type="dropzone"
                    v-model="files"
                    :error-message="$errorMessage(errors, 'thumbnail.0')"
                />
            </div>
            <app-form-group
                page="page"
                :label="$t('title')"
                type="text"
                :placeholder="$placeholder('title', '')"
                v-model="formData.title"
                :error-message="$errorMessage(errors, 'title')"
                :required="true"
            />
            <app-form-group
                page="page"
                :label="$t('place')"
                type="text"
                :placeholder="$placeholder('place', '')"
                v-model="formData.place"
                :error-message="$errorMessage(errors, 'place')"
                :required="true"
            />
            <app-form-group
                page="page"
                :label="$t('station')"
                type="text"
                :placeholder="$placeholder('station', '')"
                v-model="formData.station"
                :error-message="$errorMessage(errors, 'station')"
                :required="true"
            />
            <app-form-group
                page="page"
                type="textarea"
                label-alignment="top"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>
            <div class="form-group">
                <label>{{ $t('job_description') }}</label>
                <app-input
                    id="text-editor-for-content"
                    type="text-editor"
                    v-model="formData.content" row="4"
                    :placeholder="$textAreaPlaceHolder('job_description')"
                    :error-message="$errorMessage(errors, 'content')"
                />
            </div>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "HotelWorkPlaceCreateModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            TENANT_BASE_URL,
            files   : [],
            formData: {},
        }
    },
    methods: {
        submitData() {
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            this.files.forEach(file => formData.append('thumbnail[]', file))

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#work-place-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'work-place-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>


<template>
    <modal id="checking-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('checking')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/checking`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-12">
                    <app-form-group
                        type="switch"
                        v-model="formData.shortcoming"
                        :label="$t('shortcoming')"
                        :required="false"
                        :error-message="$errorMessage(errors, 'shortcoming')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('date')"
                        v-model="formData.date"
                        :placeholder="formData.date ? $placeholder('date') : this.$t('not_added_yet')"
                        :error-message="$errorMessage(errors, 'date')"
                    />
                </div>
                <div v-if="!hotel_id" class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('hotel')"
                        list-value-field="name"
                        v-model="formData.hotel_id"
                        :required="true"
                        :chooseLabel="$t('hotel')"
                        :error-message="$errorMessage(errors, 'hotel_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('room')"
                        list-value-field="name"
                        v-model="formData.room_id"
                        :required="true"
                        :chooseLabel="$t('room')"
                        :error-message="$errorMessage(errors, 'room_id')"
                        :fetch-url="room_url"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="time"
                        v-model="formData.start_time"
                        :label="$t('start_time')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'start_time')"
                        :placeholder="$t('enter_start_time')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="time"
                        v-model="formData.end_time"
                        :label="$t('end_time')"
                        :required="true"
                        :error-message="$errorMessage(errors, 'end_time')"
                        :placeholder="$t('enter_end_time')"
                    />
                </div>
                <div class="col-md-12">
                    <app-form-group-selectable
                        type="multi-select"
                        :label="$t('checker')"
                        list-value-field="employee_name"
                        v-model="formData.checker"
                        :chooseLabel="$t('checker')"
                        :error-message="$errorMessage(errors, 'checker')"
                        :fetch-url="member_url"
                    />
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ $t('attachments') }}</label>
                        <app-input
                            type="dropzone"
                            v-model="formData.images"
                            :error-message="$errorMessage(errors, 'images')"
                        />
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formatDateForServer, formatTimeForServer, today} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "CheckingCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel_id: {
            default: null
        },
    },
    data() {
        const current_date = formatDateForServer(today())
        return {
            TENANT_BASE_URL,
            room_url  : null,
            member_url: null,
            formData  : {
                hotel_id: this.hotel_id ? this.hotel_id : 0,
                date    : current_date,
                images  : [],
                checker : [],
            },
        }
    },
    watch  : {
        'formData.hotel_id': {
            handler  : function (value) {
                const hotel_id  = typeof value !== "undefined" ? value : 0
                this.room_url   = `${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${hotel_id}`
                this.member_url = `${TENANT_BASE_URL}selectable/hotel/member?hotel_id=${hotel_id}`
            },
            immediate: true
        }
    },
    methods: {
        submitData() {
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            formData.set('checker', JSON.stringify(this.formData.checker))

            this.formData.images.forEach(file => formData.append('images[]', file))

            if (this.formData.start_time) {
                formData.set('start_time', formatTimeForServer(this.formData.start_time))
            }
            if (this.formData.end_time) {
                formData.set('end_time', formatTimeForServer(this.formData.end_time))
            }

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            if (this.hotel_id) {
                formData.append('hotel_id', this.hotel_id);
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#checking-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'checking-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>


<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('candidate')">
            <app-default-button
                :title="$fieldTitle('add', 'candidate', true)"
                v-if="$can('create_candidate')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            class="candidate-table"
            id="candidate-table"
            :options="options"
            @action="triggerActions"
        />

        <app-candidate-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <employee-create-edit-model
            v-if="isEmployeeCreateOpenModalActive "
            v-model="isEmployeeCreateOpenModalActive"
            :selected-url="''"
            :candidate="candidate"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('candidate-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<style>
.candidate-table table tbody tr td.datatable-td {
    white-space : nowrap;
}
</style>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import CandidateMixins from "../../../Mixins/CandidateMixins";
import EmployeeCreateEditModel from "../../Employee/EmployeeCreateEditModal.vue";
import {axiosPatch} from "../../../../../common/Helper/AxiosHelper";

export default {
    name      : "AppCandidate",
    components: {EmployeeCreateEditModel},
    mixins    : [HelperMixin, CandidateMixins],
    data() {
        return {
            isModalActive                  : false,
            isEmployeeCreateOpenModalActive: false,
            selectedUrl                    : '',
            candidate                      : null,
        }
    },
    mounted() {
        this.$hub.$on('created-employee', (data) => {
            if (data.status) {
                this.candidate.recruitment = 2
                axiosPatch(`${TENANT_BASE_URL}app/candidate/${this.candidate.id}`, this.candidate).then((res) => {
                    this.candidate = null
                }).catch((error) => {
                    this.candidate = null
                    this.$toastr.e(error.response.data.message)
                }).finally(() => {
                    this.candidate = null
                    this.loading   = false;
                    this.errors    = {};
                    this.$hub.$emit('candidate-table')
                })
            }
        })
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/candidate/${row.id}`;
                this.isModalActive = true;
                this.candidate     = null;
            } else if (action.title === this.$t('convert')) {
                this.isEmployeeCreateOpenModalActive = true;
                this.candidate                       = row
            } else {
                this.candidate = null;
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>
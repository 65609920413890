import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { CONTRACT, TEMPLATE } from "../../Config/ApiUrl";
import { formatDateToLocal } from "../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: CONTRACT,
                showHeader: true,
                showCount: true,
                columns: [
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                    {
                        title: this.$t('contract_no'),
                        type: 'text',
                        key: 'contract_no',
                        isVisible: true,
                    },
                    {
                        title: this.$t('date'),
                        type: 'text',
                        key: 'date',
                        isVisible: true,
                    },
                    {
                        title: this.$t('email'),
                        type: 'text',
                        key: 'email',
                        isVisible: true,
                    },
                    {
                        title: this.$t('mobile'),
                        type: 'text',
                        key: 'mobile',
                        isVisible: true,
                    },
                    {
                        title: this.$t('employee_name'),
                        type: 'custom-html',
                        key: 'first_name',
                        isVisible: true,
                        modifier : (first_name, contract) => {
                            return contract.first_name + ' ' + contract.last_name;
                        },
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        modifier: () => this.$can('update_contract'),
                    },
                    {
                        title: this.$t('delete'),
                        icon: 'trash-2',
                        type: 'modal',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: CONTRACT,
                        name: 'delete',
                        modifier:( row) => this.$can('delete_contract') && !parseInt(row.is_default)
                    }
                ],
            },
        }
    }
}

<template>
    <div>
        <app-input
            type="text"
            v-model="remark"
            @change="updated($event.target.value)"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";

export default {
    name  : "DetailsRemark",
    mixins: [FormHelperMixins],
    props : {
        value  : {},
        tableId: {},
        rowData: {},
    },
    data() {
        return {
            remark: '',
        }
    },
    methods: {
        updated(value) {
            this.rowData.remark = value;
        },
    }
}
</script>
<template>
    <modal id="member-modal"
        v-model="showModal"
        :title="generateModalTitle('member')"
        @submit="submitData" :loading="loading"
        :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/member`'
            @submit.prevent="submitData"
        >

            <app-form-group-selectable
                :label="$fieldLabel('members', '')"
                type="multi-select"
                v-model="formData.members"
                list-value-field="full_name"
                :required="true"
                :error-message="$errorMessage(errors, 'members')"
                :fetch-url="`${apiUrl.TENANT_SELECTABLE_DEPARTMENT_USERS}?without=admin&member=only&with_auth=yes`"
            />

            <app-form-group
                type="select"
                :label="$t('role')"
                list-value-field="name"
                v-model="formData.role_id"
                :chooseLabel="$t('role')"
                :list="[
                    {id: 0, name: $t('Front')},
                    {id: 1, name: $t('Checker')},
                    {id: 2, name: $t('Maid')},
                ]"
                :error-message="$errorMessage(errors, 'role_id')"
            />

            <app-form-group
                type="switch"
                v-model="formData.verified"
                :label="$t('verified')"
                :error-message="$errorMessage(errors, 'verified')"
            />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "MemberCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type: Object,
        },
    },
    data() {
        return {
            TENANT_BASE_URL,
            formData: {
                members: [],
            },
        }
    },
    methods: {
        submitData() {
            this.loading          = true
            this.formData.members = JSON.stringify(this.formData.members);

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            formData.append('hotel_id', this.hotel.id);
            formData.append('verified', this.formData.verified ? 1 : 0);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#member-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'member-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>


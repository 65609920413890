import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        const tab = this.$parent.$props.tabs.find(e => e.component === 'app-hotel-support')
        return {
            urlGenerator,
            options: {
                name             : this.$t('hotel'),
                url              : `${TENANT_BASE_URL}app/hotel/support?hotel_id=${tab.hotel.id}`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                    {
                        title    : this.$t('qr_code'),
                        type     : 'custom-html',
                        key      : 'qr_code',
                        width    : 100,
                        isVisible: true,
                        modifier : qr_code => qr_code ? `<img src="${urlGenerator(qr_code)}" height="80px" alt="">` : ''
                    },
                    {
                        title    : this.$t('type'),
                        type     : 'text',
                        key      : 'type',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('name'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('value'),
                        type     : 'text',
                        key      : 'value',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('remark'),
                        type     : 'text',
                        key      : 'remark',
                        isVisible: true,
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_support')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/support/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_hotel_support')
                    }
                ],
            }
        }
    },
}
<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('commission')">
            <app-default-button
                :title="$t('export')"
                :btnClass="formData.length > 0 ? 'btn btn-warning mr-2' : 'disabled btn btn-warning mr-2'"
                @click="exportData()"
            />
            <app-default-button
                :title="$t('general')"
                :btnClass="formData.length > 0 ? 'btn btn-primary' : 'disabled btn btn-primary'"
                @click="submit()"
            />
        </app-page-top-section>

        <app-table
            id="commission-table"
            :options="options"
            @getRows="selectedRows"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import CommissionMixin from "../../Mixins/CommissionMixin";
import {TENANT_BASE_URL} from "../../../../common/Config/UrlHelper";
import {axiosPost, urlGenerator} from "../../../../common/Helper/AxiosHelper";

export default {
    name  : "Commission",
    mixins: [HelperMixin, CommissionMixin, TENANT_BASE_URL],
    data() {
        return {
            formData: [],
        }
    },
    methods: {
        submit() {
            axiosPost(`${TENANT_BASE_URL}app/commission`, this.formData).then((res) => {
                this.toastAndReload(res.data.message, 'commission-table');
            }).catch((error) => {
                this.$toastr.e(error.response.data.message)
            })
        },
        exportData() {
            axiosPost(`${TENANT_BASE_URL}app/commission`, this.formData).then((res) => {
                this.toastAndReload(res.data.message, 'commission-table');
                if (res.data.commission.length > 0) {
                    window.open(urlGenerator(`${this.apiUrl.EXPORT}/commission?ids=` + res.data.commission.map(e => e.id)), '_blank')
                }
            }).catch((error) => {
                this.$toastr.e(error.response.data.message)
            })
        },
        selectedRows(rows) {
            this.formData = rows
        },
    }
}
</script>
<template>
    <div class="content-wrapper">
        <app-tab :tabs="tabs" :icon="tabIcon"/>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name : "AppHotelEdit",
    props: {
        hotel: {},
    },
    data() {
        const tabs = []

        if (this.hotel.name) {
            tabs.push(
                {
                    'name'      : this.$t('room'),
                    'title'     : this.$t('room'),
                    'component' : 'app-hotel-room',
                    'permission': 'view_hotel_room',
                    'hotel'     : this.hotel
                },
                {
                    'name'      : this.$t('type'),
                    'title'     : this.$t('type'),
                    'component' : 'app-hotel-type',
                    'permission': 'view_hotel_type',
                    'hotel'     : this.hotel
                },
                {
                    'name'      : this.$t('status'),
                    'title'     : this.$t('status'),
                    'component' : 'app-hotel-house-keeping-status',
                    'permission': 'view_hotel_house_keeping_status',
                    'hotel'     : this.hotel
                },
                {
                    'name'      : this.$t('option'),
                    'title'     : this.$t('option'),
                    'component' : 'app-hotel-option',
                    'permission': 'view_hotel_option',
                    'hotel'     : this.hotel
                },
                {
                    'name'      : this.$t('pricing'),
                    'title'     : this.$t('pricing'),
                    'component' : 'app-hotel-pricing',
                    'permission': 'view_hotel_pricing',
                    'hotel'     : this.hotel
                },
                {
                    'name'      : this.$t('member'),
                    'title'     : this.$t('member'),
                    'component' : 'app-hotel-member',
                    'permission': 'view_hotel_member',
                    'hotel'     : this.hotel
                },
            );
        }

        tabs.push({
            'name'      : this.$t('settings'),
            'title'     : this.$t('settings'),
            'component' : 'app-hotel-settings',
            'permission': 'update_hotel_list',
            'hotel'     : this.hotel
        });

        return {
            tabIcon: 'user',
            tabs   : tabs
        }
    },
    computed: {
        ...mapState({
            loader: state => state.loading,
        }),
    },
}
</script>
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";
import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [HelperMixin, DatatableHelperMixin],
    data() {
        return {
            options: {
                name             : this.$t('visit'),
                url              : `${TENANT_BASE_URL}app/sale-visit`,
                showHeader       : true,
                showCount        : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: this.cardId
                    },
                    {
                        title    : this.$t('card'),
                        type     : 'custom-html',
                        key      : 'card',
                        isVisible: true,
                        modifier : (value) => value ? value.name : ''
                    },
                    {
                        title    : this.$t('company_name'),
                        type     : 'custom-html',
                        key      : 'card',
                        isVisible: true,
                        modifier : (value) => value ? value.company_name : ''
                    },
                    {
                        title    : this.$t('start_time'),
                        type     : 'text',
                        key      : 'start_time',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('end_time'),
                        type     : 'text',
                        key      : 'end_time',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('date'),
                        type     : 'custom-html',
                        key      : 'date',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                    {
                        title    : this.$t('type'),
                        type     : 'text',
                        key      : 'type_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('status'),
                        type     : 'text',
                        key      : 'status_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('image'),
                        type     : 'custom-html',
                        key      : 'image',
                        isVisible: true,
                        modifier : (files) => {
                            let html = '';

                            if (files && files.length > 0) {
                                for (const file_url of files) {
                                    html += `<a href="${urlGenerator(file_url)}" target="_blank" class="d-block">${this.$t('download')}</a>`
                                }
                            }

                            return html
                        }
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : !this.cardId,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_sale_visit')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/sale-visit/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_sale_visit')
                    }
                ],
            }
        }
    },
}
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {DOCUMENT_TYPE} from "../../Config/ApiUrl";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name          : this.$t('tenant_groups'),
                url           : DOCUMENT_TYPE,
                showHeader    : true,
                showCount     : true,
                columns       : [
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'invoice',
                        isVisible: true
                    },
                    {
                        title    : this.$t('name'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('description'),
                        type     : 'custom-html',
                        key      : 'description',
                        isVisible: true,
                        modifier : (description) => {
                            return description ? description : '-';
                        }
                    },
                ],
                paginationType: "pagination",
                responsive    : true,
                rowLimit      : 10,
                showAction    : true,
                orderBy       : 'desc',
                actionType    : "default",
                actions       : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: () => this.$can('update_document_type')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : DOCUMENT_TYPE,
                        name     : 'delete',
                        modifier : (row) => this.$can('delete_document_type') && !parseInt(row.is_default)
                    }
                ],
            },
        }
    }
}

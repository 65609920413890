<template>
    <div class="login-form d-flex align-items-center" style="min-height: auto; margin-top: 100px;">
        <form class="sign-in-sign-up-form w-100" ref="form" data-url="admin/users/login">
            <div class="text-center mb-4">
                <img :src="logoUrl" class="img-fluid logo dark-logo" alt="logo"/>
                <!--<img :src="logoWhiteUrl" class="img-fluid logo light-logo" alt="logo"/>-->
            </div>
            <div class="form-row">
                <div class="form-group col-12 mb-2">
                    <h6 class="text-center mb-0">{{ $t('hi', {object: $t('there')}) }}!</h6>
                    <label class="text-center d-block">{{ $t('login_to_your_dashboard') }}</label>
                </div>
            </div>

            <app-form-group
                type="select"
                v-if="demoCredentials"
                v-model="loginRole"
                :label="$t('login_as')"
                list-value-field="name"
                :list="selectableRole"
            />

            <app-form-group
                :label="$t('email')"
                type="email"
                v-model="formData.email"
                :placeholder="$placeholder('your', 'email')"
                :error-message="$errorMessage(errors, 'email')"
            />

            <app-form-group
                :label="$t('password')"
                type="password"
                v-model="formData.password"
                :placeholder="$placeholder('your', 'password')"
                :error-message="$errorMessage(errors, 'password')"
                :show-password="true"
            />

            <div class="form-row">
                <div class="form-group col-12">
                    <app-submit-button
                        btn-class="d-inline-flex btn-block text-center btn-primary"
                        :label="$t('login')"
                        :loading="loading"
                        @click="submitData"
                    />
                </div>
            </div>

            <div class="form-row">
                <div class="col-6">
                    <app-input
                        class="mb-primary"
                        type="single-checkbox"
                        :list-value-field="$t('remember_me')"
                        v-model="formData.remember_me"
                    />
                </div>

                <div class="col-6 text-right">
                    <a :href="urlGenerator('/users/password-reset')" class="bluish-text">
                        <i data-feather="lock" class="pr-2"/>{{ $t('forget', {subject: $t('password')}) }}?
                    </a>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-between">

                <p class="text-center footer-copy m-0">
                    {{ $t('copyright') }} @ {{ new Date().getFullYear() }} {{ $t('by') }} {{ appName }}
                </p>

                <div>
                    <a href="#"
                       id="languageDropdown"
                       class="d-flex align-items-center nav-link dropdown-toggle p-0"
                       data-toggle="dropdown"
                       aria-expanded="false">
                        <app-icon name="globe" class="p-1"/>
                        {{ selectedLanguage }}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown language-dropdown"
                         aria-labelledby="languageDropdown">
                        <a class="dropdown-item" v-for="(item, index) in languages" :href="item.url" :key="index">
                            <div class="d-flex align-items-center">
                                <h6 class="mb-0 font-weight-normal">{{ item.title }}</h6>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import ThemeMixin from "../../Mixin/Global/ThemeMixin";
import FormHelperMixins from "../../Mixin/Global/FormHelperMixins";
import {mapActions, mapState} from 'vuex';
import CoreLibrary from "../../../core/helpers/CoreLibrary";

export default {
    name   : "Login",
    extends: CoreLibrary,
    mixins : [ThemeMixin, FormHelperMixins],
    props  : {
        logoUrl     : {
            required: false
        },
        logoWhiteUrl: {
            required: false
        },
        appName     : {
            required: false
        },
        previousPage: {
            required: false
        },
        demo        : {}
    },
    data() {
        return {
            loginRole: ''
        }
    },
    created() {
        this.getLanguages();
    },
    methods : {
        ...mapActions([
            'getLanguages'
        ]),
        submitData() {
            this.message = '';
            this.loading = true;
            this.save(this.formData);
        },
        afterSuccess({data}) {
            window.location.href = data;
        },
        afterFinalResponse() {
        },
    },
    computed: {
        ...mapState({
            languages: state => state.support.languages
        }),
        demoCredentials() {
            return this.demo ? JSON.parse(this.demo) : '';
        },
        selectableRole() {
            return [{id: '', name: this.$t('select_role')}]
                .concat(Object.keys(this.demoCredentials).map((item) => {
                    return {
                        id  : item,
                        name: this.demoCredentials[item].role,
                    }
                }))
        },
        selectedLanguage() {
            if (window.appLanguage) {
                return window.appLanguage.toUpperCase();
            }
        },
        loginRoleUpdate() {
            return this.loginRole
        }
    },
    watch   : {
        loginRoleUpdate: {
            handler: function (role) {
                this.formData.password = role ? this.demoCredentials[role].password : '';
                this.formData.email    = role ? this.demoCredentials[role].email : '';
            }
        }
    }

}
</script>

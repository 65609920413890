import {axiosGet, urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";
import {HotelFilterMixin} from "./FilterMixin";

export default {
    mixins: [HelperMixin, DatatableHelperMixin, HotelFilterMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('lost-found'),
                url              : `${TENANT_BASE_URL}app/hotel/lost-found`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                    {
                        title    : this.$t('hotel'),
                        type     : 'custom-html',
                        key      : 'hotel',
                        isVisible: true,
                        modifier : (hotel) => {
                            return hotel ? hotel.name : '';
                        }
                    },
                    {
                        title    : this.$t('room_number'),
                        type     : 'custom-html',
                        key      : 'room',
                        isVisible: true,
                        modifier : (room) => {
                            return room ? room.number : '';
                        }
                    },
                    {
                        title        : this.$t('attachments'),
                        type         : 'component',
                        key          : 'files',
                        isVisible    : true,
                        componentName: 'app-hotel-lost-found-preview',
                    },
                    {
                        title    : this.$t('remark'),
                        type     : 'textarea',
                        key      : 'remark',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('date'),
                        type     : 'custom-html',
                        key      : 'date',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('hotel'),
                        type          : "multi-select-filter",
                        key           : "hotels",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_list')
                    },
                    {
                        title         : this.$t('floor'),
                        type          : "multi-select-filter",
                        key           : "floor",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_room')
                    },
                    {
                        title         : this.$t('room_number'),
                        type          : "multi-select-filter",
                        key           : "rooms",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_room')
                    },
                    // {
                    //     title   : this.$t('room_number'),
                    //     minTitle: this.$t('from'),
                    //     maxTitle: this.$t('to'),
                    //     type    : "range-filter",
                    //     key     : "number",
                    //     maxRange: 500,
                    //     minRange: 0,
                    // },
                    {
                        title : this.$t('date'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('process'),
                        icon    : 'repeat',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_lost_found')
                    },
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_lost_found')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/lost-found/`,
                        name     : 'delete',
                        modifier : row => this.$can('update_hotel_lost_found')
                    }
                ],
            }
        }
    },
    methods: {
        getFilterValues(value) {
            const Error       = error => this.$toastr.e(error.response.data.message)
            const floorFilter = this.options.filters.find(item => item.key === 'floor');
            const roomFilter  = this.options.filters.find(item => item.key === 'rooms');

            if (value.hotels) {
                floorFilter.option = []
                roomFilter.option  = []
                axiosGet(`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${value.hotels}&group=floor&format=floor`)
                    .then(({data}) => floorFilter.option = data)
                    .catch(Error)
            }

            if (value.floor) {
                axiosGet(`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${value.hotels}&floor=${value.floor}`)
                    .then(({data}) => roomFilter.option = data)
                    .catch(Error)
            }
        },
    },
}
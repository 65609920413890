import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { AGREEMENT_ITEM } from "../../Config/ApiUrl";
import { axiosPatch } from "../../../common/Helper/AxiosHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url:  this.props.template_id ? `${AGREEMENT_ITEM}?template_id=${this.props.template_id}` : AGREEMENT_ITEM,
                showHeader: !this.props.is_in_tab,
                showCount: !this.props.is_in_tab,
                tableShadow: !this.props.is_in_tab,
                isRowDragAble: this.props.is_in_tab,
                dragCompleteHandler: (event) => {
                    if (event.oldIndex === event.newIndex) {
                        return;
                    }

                    const data = {
                        oldOrder: event.oldIndex,
                        newOrder: event.newIndex,
                        template_id: this.props.template_id,
                    };
                    axiosPatch(`app/update-order-agreement-item`, data).then(({data}) => {
                        this.toastAndReload(data.message, 'agreement-item-table')
                    }).catch(error => {
                        this.errors = error.response.data.errors;
                        this.message = '';
                    })
                },
                columns: [
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                    {
                        title: this.$t('order'),
                        type: 'custom-html',
                        key: 'order',
                        isVisible: true,
                        modifier: value => {
                            return `<div class="cursor-grab"><svg style="height: 24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify text-muted"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg></div>`
                        },
                    },
                    {
                        title: this.$t('name'),
                        type: 'text',
                        key: 'name',
                        isVisible: true,
                    },
                    {
                        title: this.$t('content'),
                        type: 'text',
                        key: 'content',
                        isVisible: true,
                    },
                    {
                        title: this.$t('remark'),
                        type: 'text',
                        key: 'remark',
                        isVisible: true,
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: this.props.is_in_tab ? 1000 : 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        modifier: () => this.$can('update_agreement_item'),
                    },
                    {
                        title: this.$t('delete'),
                        icon: 'trash-2',
                        type: 'modal',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: AGREEMENT_ITEM,
                        name: 'delete',
                        modifier:( row) => this.$can('delete_agreement_item') && !parseInt(row.is_default)
                    }
                ],
            },
        }
    }
}

<template>
    <div>
        <app-overlay-loader v-if="preloader"/>
        <form v-else
            ref="form"
            method="post"
            :data-url="`${TENANT_BASE_URL}general/settings/keieihub`"
            @submit.prevent="submitData"
            enctype="multipart/form-data"
            class="mb-0"
            :class="{'loading-opacity': preloader}">

            <!-- Firebase Settings -->
            <fieldset class="form-group mb-5">
                <div class="row">
                    <legend class="col-12 col-form-label text-primary pt-0 mb-3">
                        {{ $t('keieihub') }}
                    </legend>
                    <div class="col-md-12">
                        <app-form-group
                            page="page"
                            :label="$t('api_key')"
                            type="text"
                            v-model="settings.keieihub_api_key"
                            :error-message="$errorMessage(errors, 'keieihub_api_key')"
                        />
                    </div>
                    <div class="col-md-12">
                        <app-form-group
                            page="page"
                            :label="$t('password')"
                            type="password"
                            id="input-text-password"
                            :placeholder="$t('password')"
                            v-model="settings.keieihub_password"
                            :error-message="$errorMessage(errors, 'keieihub_password')"
                            :show-password="true"
                        />
                    </div>
                </div>
            </fieldset>

            <div class="mt-5">
                <app-submit-button
                    :loading="loading"
                    @click="submitData"
                />
            </div>
        </form>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {formDataAssigner} from '../../../../../common/Helper/Support/FormHelper'
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins"

export default {
    name  : "TenantKeieihubSetting",
    mixins: [FormHelperMixins],
    data() {
        return {
            TENANT_BASE_URL,
            settings: {},
        }
    },
    methods : {
        beforeSubmit() {
            this.preloader = true;
            this.loading   = true;
            this.message   = '';
            this.errors    = {};
        },
        submitData() {
            let formData = formDataAssigner(new FormData, this.settings);
            return this.save(formData);
        },
        afterSuccess({data}) {
            this.toastAndReload(data.message);
            location.reload();
        },
        ...mapActions([
            'getConfig',
            'getTenantSettings'
        ]),
    },
    computed: {
        ...mapState({
            languages      : state => state.support.languages,
            generalSettings: state => state.tenant_settings.tenantSettings
        }),
        tenant() {
            return window.tenant;
        },
    },
    created() {
        this.getConfig();
        this.getTenantSettings();
        this.preloader = true;
    },
    watch: {
        generalSettings: {
            handler: function (settings) {
                if (!Object.keys(this.settings).length) {
                    for (const key in settings) {
                        if (![
                            'keieihub_api_key',
                            'keieihub_password',
                        ].includes(key)) {
                            continue
                        }
                        this.settings[key] = settings[key];
                    }
                    this.preloader = false;
                }
            },
            deep   : true
        }
    },

}
</script>

import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('hotel'),
                url              : `${TENANT_BASE_URL}app/hotel/list`,
                showHeader       : true,
                showCount        : true,
                showClearFilter  : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                cardViewComponent: 'app-hotel-card',
                columns          : [
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                    {
                        title    : this.$t('name'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('acronym'),
                        type     : 'text',
                        key      : 'acronym',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('address'),
                        type     : 'text',
                        key      : 'address',
                        isVisible: true,
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'dropdown',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_list')
                    },
                    {
                        title   : this.$t('achievement'),
                        icon    : 'achievement',
                        url     : `${TENANT_BASE_URL}app/hotel/achievement/`,
                        type    : 'modal',
                        modifier: row => this.$can('create_hotel_achievement')
                    },
                    {
                        title   : this.$t('house_keeping'),
                        icon    : 'house_keeping',
                        type    : 'modal',
                        url     : `${TENANT_BASE_URL}app/hotel/house-keeping/`,
                        modifier: row => this.$can('create_hotel_house_keeping')
                    },
                    {
                        title   : this.$t('claim'),
                        icon    : 'claim',
                        type    : 'modal',
                        url     : `${TENANT_BASE_URL}app/hotel/claim/`,
                        modifier: row => this.$can('create_hotel_claim')
                    },
                    {
                        title   : this.$t('lost_and_found'),
                        name    : 'lost_found',
                        type    : 'modal',
                        url     : `${TENANT_BASE_URL}app/hotel/lost-found/`,
                        modifier: row => this.$can('create_hotel_lost_found')
                    },
                    {
                        title   : this.$t('checking'),
                        name    : 'checking',
                        type    : 'modal',
                        url     : `${TENANT_BASE_URL}app/hotel/checking/`,
                        modifier: row => this.$can('create_hotel_checking')
                    },
                    {
                        title   : this.$t('status'),
                        name    : 'status',
                        type    : 'modal',
                        modifier: row => this.$can('view_hotel_status')
                    },
                    {
                        title   : this.$t('work_shift'),
                        name    : 'work_shift',
                        type    : 'modal',
                        modifier: row => this.$can('view_hotel_list')
                    },
                    {
                        title   : this.$t('report'),
                        name    : 'report',
                        type    : 'modal',
                        modifier: row => this.$can('view_hotel_list')
                    },
                    {
                        title   : this.$t('share'),
                        name    : 'share',
                        type    : 'modal',
                        modifier: row => this.$can('view_hotel_list')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/list`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_hotel_list')
                    }
                ],
            }
        }
    },
}
<template>
    <modal id="status-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('status')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/house-keeping-status`'
            @submit.prevent="submitData"
        >
            <app-form-group
                :label="$t('name')"
                type="text"
                id="input-text-name"
                :placeholder="$placeholder('name', '')"
                v-model="formData.name"
                :error-message="$errorMessage(errors, 'name')"
            />
            <app-form-group
                v-if="viewOrder"
                :label="$t('order')"
                type="number"
                id="input-text-order"
                :placeholder="$placeholder('order', '')"
                v-model="formData.order"
                :error-message="$errorMessage(errors, 'order')"
            />
            <app-form-group
                :label="$t('color')"
                type="color"
                id="input-text-color"
                :placeholder="$placeholder('color', '')"
                v-model="formData.color"
                :error-message="$errorMessage(errors, 'color')"
            />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "HouseKeepingStatusCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type: Object,
        },
    },
    data() {
        return {
            TENANT_BASE_URL,
            viewOrder: false,
            formData : {
                order: 0,
                color: '#000'
            },
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#status-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'status-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

